import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { requestRacesLeaderboard } from "../../store/actionCreators/racesLeaderboard";
import { classNames } from "../../helpers/classNames";
import Button from "../common/Button";
import { useFormatCurrency } from "../../hooks/useFormatCurrency";
import "./GameCard.scss";
import { getAwardPlacement } from "../../store/selectors";
import { storeAwardPlacement } from "../../store/actionCreators/awardPlacement";

const GameCard = ({ selectedRaceId, race, isCompleted, children, cb, setIdCb }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formatCurrency = useFormatCurrency();
  const [expanded, setIsExpanded] = useState(false);
  const awarwdPlacement = useSelector(getAwardPlacement)(race?.id);

  const handleSelectRace = useCallback((race, limit) => {
    if (setIdCb) {
      setIdCb(race?.id);
    }
    if (!Object.keys(awarwdPlacement)?.length) {
      dispatch(storeAwardPlacement({ id: race?.id, awardHeaders: race?.prizeStructure?.headers }))
    }
    dispatch(requestRacesLeaderboard({ raceId: race?.id, limit }));
  }, [awarwdPlacement]);

  useEffect(() => {
    if (expanded && selectedRaceId !== race.id) {
      setIsExpanded(false);
    }
  }, [expanded, selectedRaceId, race.id]);

  const handleExpandItem = (race, limit) => {
    setIsExpanded((prevState) => {
      if (!prevState) {
        if (cb) {
          cb(race?.id);
        }
        handleSelectRace(race, limit);
      }
      return !prevState;
    });
  };

  const date = isCompleted
    ? t("completed")
    : `${t("startDate")}: ${moment(race?.start_time).format("DD MMMM")}`;

  return (
    <div
      className={classNames("game-card", {
        "game-card--completed": isCompleted,
      })}
    >
      <div className="game-card__header">
        {isCompleted && <span className="game-card__header-compleate-date">{moment(race?.expire_time).format("DD.MM.YY")}</span>}
        <span className="game-card__header-date">{date}</span>
      </div>
      <div className="game-card__body">
        <div className="game-card__body-inner">
          <div className="game-card__img-wrapper">
            <img
              src={race?.img_url || `/static/images/upcoming-race.png`}
              alt="Thubnail"
            />
          </div>
          <div className="game-card__name-prize-id-wrapper">
            <div className="game-card__name-prize-wrapper">
              <span className="game-card__name">{race?.name}</span>
              <span className="game-card__prize">
                {formatCurrency(race?.prize_pool, race?.currency)}
              </span>
            </div>
            <div className="game-card__id-wrapper">
              <span className="game-card__id">{`${t("id")}: ${race?.id}`}</span>
            </div>
          </div>
          {isCompleted && !isMobile && (
            <div className="game-card__button-wrapper">
              <Button
                onClick={() => handleSelectRace(race, race?.accountsCount || 20)}
                variant="primary"
              >
                {t("ranking")}
              </Button>
            </div>
          )}
          {isCompleted && isMobile && (
            <div
              onClick={() => handleExpandItem(race, race?.accountsCount || 20)}
              className="game-card__arrow-wrapper"
            >
              <ReactSVG src="/static/images/icon-expand-circle-down.svg" />
            </div>
          )}
        </div>
        {(children && expanded && selectedRaceId === race.id) && children}
      </div>
    </div>
  );
};

export default GameCard;
