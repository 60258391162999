import types from "../actionTypes";

export const setQueryParams = (payload) => ({
  type: types.SET_QUERY_PARAMS,
  payload,
});

export const loginSetQueryParams = (payload) => ({
  type: types.LOGIN_SET_QUERY_PARAMS,
  payload,
});

export const logoutSetQueryParams = () => ({
  type: types.LOGOUT_SET_QUERY_PARAMS,
});
