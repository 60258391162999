const currencySymbols = {
  'USD': '$',
  'EUR': '€',
  'TRY': '₺',
  'TND': 'DT',
  'INR': '₹',
  'NPR': 'रु',
  'BDT': '৳',
  'BRL': 'R$',
  'AED': 'AED',
  'PYG': '₲'
};

export const getCurrencySymbol = currency => currencySymbols[currency] || '';

export const formatToOrdinal = number => {
  const lim = number % 10;
  if ((number > 3 && number < 20) || lim > 3) return `${number}th`;
  if (lim === 3) return `${number}rd`;
  if (lim === 2) return `${number}nd`;
  if (lim === 1) return `${number}st`;
  return `${number}th`;
};

export const formatBalance = (amount, currency) => {
  return getCurrencySymbol(currency) + ' ' + parseFloat(parseFloat(amount).toFixed(2));
}