import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import GameCard from "../../../GameCard";
import Tabs from "../../../Tabs";
import { useCallback, useState } from "react";
import MyAccountResult from "../../../MyAccountResult";
import { getAwardPlacement } from "../../../../store/selectors";
import "./DesktopPreviousRacesView.scss";

const DesktopPreviousRacesView = ({
  handleCurrentTableChange,
  currentTable,
  previousRaces,
  TableView,
}) => {
  const { t } = useTranslation();
  const [raceId, setRaceId] = useState(() => previousRaces[0]?.id);
  const awarwdPlacement = useSelector(getAwardPlacement)(raceId);

  const handleSetRaceId = useCallback((id) => setRaceId(id), []);

  return (
    <div className="previous-races-view">
      <div className="previous-races-view__race-list">
        {previousRaces.map((race) => (
          <div className="previous-races-view__race-item" key={race.id}>
            <GameCard
              setIdCb={handleSetRaceId}
              race={race}
              isCompleted
            />
          </div>
        ))}
      </div>
      <div className="previous-races-view__selected-race-wrapper">
        <div className="previous-races-view__tabs-my-account-result-wrapper">
          <div className="previous-races-view__tabs-wrapper">
            <Tabs
              tabs={[
                { label: t("leaderboard"), value: "leaderboard" },
                { label: t("prizepool"), value: "prizepool" },
              ]}
              selectedTab={currentTable}
              onChange={handleCurrentTableChange}
              variant="secondary"
              backgroundColor="var(--BG-Surface-3, #2E3138)"
            />
          </div>
          <div className="previous-races-view__my-account-result-wrapper">
            <MyAccountResult id={raceId} />
          </div>
        </div>
        {Object.keys(awarwdPlacement)?.length > 0 && <div className="previous-races-view__table-content-wrapper">
          <TableView id={raceId} />
        </div>}
      </div>
    </div>
  );
};

export default DesktopPreviousRacesView;
