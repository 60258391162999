import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';


import Button from '../common/Button';
import Spinner from '../common/Spinner';

import {
  getCurrentRace,
  getIsJoinToRacePending,
  getRaceVisualMode,
  getSelectedRace,
  getToken
} from '../../store/selectors';
import { selectRace } from '../../store/actionCreators/races';
import { requestJoinToRace } from '../../store/actionCreators/joinToRace';

import settings from '../../configs/settings';
import './index.scss';


const JoinToRace = ({ race }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const selectedRace = useSelector(getSelectedRace);
  const currentRace = useSelector(getCurrentRace);
  const onlyInProgress = useSelector(getRaceVisualMode);
  const isJoinPending = useSelector(getIsJoinToRacePending);

  const handleJoinToRace = (race) => {
    if (settings?.externalLinkPlayButton) {
      window.open(settings?.externalLinkPlayButton, '_blank');
    } else {
      if (!token) {
        if(onlyInProgress) {
          window.parent.parent.postMessage({ type: 'OPEN_LOGIN' }, "*");
        }else {
          window.parent.postMessage({ type: 'OPEN_LOGIN' }, "*");
        }
        return;
      }
      if (!race?.joinAvailable) {
        if(onlyInProgress) {
          window.parent.parent.postMessage({ type: "CLOSE_GAME_BOX_GLOBAL_WIDGET" }, "*");
          window.parent.parent.postMessage({ type: "CLOSE_GAME_BOX" }, "*");
          window.parent.parent.postMessage({ type: 'CHANGE_CATEGORY', category: 'casino' }, "*");
        }else {
          window.parent.postMessage({ type: 'CHANGE_CATEGORY', category: 'casino' }, "*");
        }
        return;
      }
      dispatch(requestJoinToRace(race?.id));
      dispatch(selectRace(race));
    }
  }

  return (
    <Button
      variant={"primary"}
      disabled={!race?.joinAvailable && currentRace === 'pending'}
      onClick={() => handleJoinToRace(race)}
    >
      {isJoinPending && race?.id === selectedRace?.id ?
        <Spinner />
        : t(race?.joinAvailable ? 'optIn' : 'play')
      }
    </Button>
  )
}

export default memo(JoinToRace);
