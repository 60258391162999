import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import Tabs from "../../Tabs";
import CountDownTimer from "../../CountDownTimer";
import MyAccountResult from "../../MyAccountResult";
import JoinToRace from "../../JoinToRace";
import Button from "../../common/Button";
import EmptyAccountResult from "../../EmptyAccountResult";
import { useFormatCurrency } from "../../../hooks/useFormatCurrency";
import "./DesktopLeaderboardAndPrizepool.scss";

const DesktopLeaderboardAndPrizepool = ({
  visibleItems,
  item,
  currentTable,
  TableView,
  handleShowMore,
  handleCurrentTableChange,
  handleCountDownAction,
  hasAccountResult,
}) => {
  const { t } = useTranslation();
  const { name, prize_pool, currency, expire_time, id, accountsCount } = item;
  const formatCurrency = useFormatCurrency();

  return (
    <div className="leaderboard-and-prizepool">
      <div className="leaderboard-and-prizepool__header">
        <div className="leaderboard-and-prizepool__headder-inner">
          <div className="leaderboard-and-prizepool__header-top">
            <div className="leaderboard-and-prizepool__header-img" />
            <div className="leaderboard-and-prizepool__header-name-prizepool-wrapper">
              <span className="leaderboard-and-prizepool__header-name">
                {name}
              </span>
              <div className="leaderboard-and-prizepool__header-prizepool-wrapper">
                <div className="leaderboard-and-prizepool__header-prizepool-img" />
                <span className="leaderboard-and-prizepool__header-prizepool">
                  {formatCurrency(prize_pool, currency)}
                </span>
              </div>
            </div>
          </div>
          <div className="leaderboard-and-prizepool__header-bottom">
            <div className="leaderboard-and-prizepool__info-wrapper leaderboard-and-prizepool__info-wrapper--timer">
              <span className="leaderboard-and-prizepool__info-label">
                {t("endsIn")}:
              </span>
              <div className="leaderboard-and-prizepool__info-value">
                <CountDownTimer
                  withoutLabels
                  withDots
                  countdownTimestampMs={expire_time}
                  countDownCallback={handleCountDownAction}
                />
              </div>
            </div>
            <div className="leaderboard-and-prizepool__info-wrapper">
              <span className="leaderboard-and-prizepool__info-label">
                {t("id")}:
              </span>
              <span className="leaderboard-and-prizepool__info-value">
                {id}
              </span>
            </div>
            <div className="leaderboard-and-prizepool__info-wrapper">
              <span className="leaderboard-and-prizepool__info-label leaderboard-and-prizepool__info-label--icon">
                <ReactSVG src="static/images/icon-enrolled.svg" />
              </span>
              <span className="leaderboard-and-prizepool__info-value">
                {accountsCount}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="leaderboard-and-prizepool__body">
        <div className="leaderboard-and-prizepool__tabs-my-account-result-wrapper">
          <div className="leaderboard-and-prizepool__tabs-my-account-result-wrapper-inner">
            <div className="leaderboard-and-prizepool__tabs-wrapper">
              <Tabs
                tabs={[
                  { label: t("leaderboard"), value: "leaderboard" },
                  { label: t("prizepool"), value: "prizepool" },
                ]}
                selectedTab={currentTable}
                onChange={handleCurrentTableChange}
                variant="secondary"
                backgroundColor="var(--BG-Surface-3, #2E3138)"
              />
            </div>
            {hasAccountResult ? (
              <div className="leaderboard-and-prizepool__my-account-result-wrapper">
                <MyAccountResult id={item.id} />
              </div>
            ) : (
              <div className="leaderboard-and-prizepool__empty-result-wrapper">
                <EmptyAccountResult />
              </div>
            )}
          </div>
        </div>
        <TableView id={String(id)} visibleItems={visibleItems} />
        <div className="leaderboard-and-prizepool__actions">
          <Button
            variant="secondary"
            disabled={visibleItems === "all"}
            onClick={handleShowMore}
          >
            {t("showMore")}
          </Button>
          <JoinToRace race={item} />
        </div>
      </div>
    </div>
  );
};

export default DesktopLeaderboardAndPrizepool;
