export const prop = (key) => (obj) => obj?.[key];

export const maskString = (str) => {
  if(!str && typeof str !== 'string') return;
  let firstTwoLetters;
  let lastTwoLetters;
  if (str.length < 7) {
    firstTwoLetters = str.slice(0, 2);
    lastTwoLetters = str.slice(-1);
  } else {
    firstTwoLetters = str.slice(0, 3);
    lastTwoLetters = str.slice(-2);
  }
  return `${firstTwoLetters}***${lastTwoLetters}`;
}

export const updateImageSrc = (htmlString, domain) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;
  const imgTags = tempDiv.querySelectorAll("img");

  imgTags.forEach((imgTag) => {
    const relativeSrc = imgTag.getAttribute("src");
    if (relativeSrc) {
      const absoluteSrc = `${domain}${relativeSrc}`;
      imgTag.setAttribute("src", absoluteSrc);
    }

    const srcset = imgTag.getAttribute("srcset");
    if (srcset) {
      const updatedSrcset = srcset
        .split(",")
        .map((srcsetItem) => {
          const parts = srcsetItem.trim().split(" ");
          const relativeSrcset = parts[0];
          const absoluteSrcset = `${domain}${relativeSrcset}`;
          return `${absoluteSrcset} ${parts.slice(1).join(" ")}`;
        })
        .join(", ");
      imgTag.setAttribute("srcset", updatedSrcset);
    }
  });

  return tempDiv.innerHTML;
};

export const isNumber = (value) => !Number.isNaN(Number(value));

export const isValidHttpsUrl = (string) => {
  const regex = new RegExp('^https://[^\\s/$.?#].[^\\s]*$', 'i');
  return regex.test(string);
}
