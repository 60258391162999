import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getToken } from "../../store/selectors";
import "./EmptyAccountResult.scss";

const EmptyAccountResult = () => {
  const { t } = useTranslation();
  const token = useSelector(getToken);
  return (
    <div className="empty-account-result">
      <span className="empty-account-result__text">{t(token ? "emptyAccountResult" : "emptyAccountResultWithoutToken")}</span>
    </div>
  );
};

export default EmptyAccountResult;
