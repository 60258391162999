import {
  take,
  fork,
  call,
  put,
  select,
  delay,
} from 'redux-saga/effects';

import { apiPost } from './callApi';

import actionTypes from '../actionTypes';
import { getToken } from '../selectors';
import { failJoinToRace, successJoinToRace, updateJoinToRacePendingStatus } from '../actionCreators/joinToRace';

import apiRoutes from '../../constants/api/apiRoutes';

function* joinToRace({ raceId }) {
  try {
    const headers = {};
    const params = {};

    const token = yield select(getToken);

    if (token) {
      params['token'] = token;
      headers['x-access-token'] = JSON.stringify(token);
    }

    if (raceId) {
      params['raceId'] = raceId;
    }

    const response = yield call(apiPost, 'gameBoxUrl', apiRoutes.JOIN_TO_RACE, params, headers);
    if (response?.data?.success) {
      yield put(successJoinToRace(response.data?.result?.races));
      yield delay(1000);
      yield put(updateJoinToRacePendingStatus());
    } else {
      yield put(failJoinToRace(response?.data?.description || response?.data?.message));
    }
  } catch (e) {
    yield put(failJoinToRace(e));
  }
}

export default function* watchJoinToRaceRequest() {
  while (true) {
    const action = yield take(actionTypes.JOIN_TO_RACE_REQUEST);
    yield fork(joinToRace, action);
  }
}
