import { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setQueryParams } from "../../../store/actionCreators/queryParams";
import { requestRaces } from "../../../store/actionCreators/races";
import { useQuery } from "../../../hooks/useQuery";
import LeaderboardAndPrizepool from "../../LeaderboardAndPrizepool";
import { getOngoingRaces, isRacesLoading } from "../../../store/selectors";
import "./OngoingView.scss";
import Skeleton from "../../Skeleton";
import { isMobile } from "react-device-detect";

const OngoingView = () => {
  const dispatch = useDispatch();
  const queries = useQuery();
  const races = useSelector(getOngoingRaces);
  const pending = useSelector(isRacesLoading);

  useEffect(() => {
    if (Object.keys(queries)?.length) {
      dispatch(requestRaces());
    }
  }, [Object.keys(queries)?.length]);

  useLayoutEffect(() => {
    dispatch(setQueryParams(queries));
  }, [queries, dispatch]);

  return (
    <div className="ongoing-view">
      {pending ? (
        <Skeleton
          radius={8}
          width={isMobile ? '100%' : 771}
          height={isMobile ? 655 : 693}
          style={{ display: "flex", justifyContent: "center" }}
        />
      ) : (
        races?.length > 0 &&
        races?.map((item) => (
          <div
            key={item?.id}
            className="ongoing-view__leaderboard-and-prizepool-wrapper"
          >
            <LeaderboardAndPrizepool item={item} />
          </div>
        ))
      )}
    </div>
  );
};

export default OngoingView;
