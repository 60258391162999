import {
  call,
  fork,
  put,
  select,
  take,
} from 'redux-saga/effects';

import { apiGet } from './callApi';

import actionTypes from '../actionTypes';
import { getRaces, getSelectedRace, getSkinId, getToken } from '../selectors';
import { failRacesLeaderboard, successRacesLeaderboard } from '../actionCreators/racesLeaderboard';

import apiRoutes from '../../constants/api/apiRoutes';
import { selectRace } from '../actionCreators/races';

function* getRacesLeaderboard({ limit, raceId }) {
  try {
    
    const token = yield select(getToken);
    const skinId = yield select(getSkinId);
    const selectedRace = yield select(getSelectedRace);
    const races = yield select(getRaces);
    const headers = {};
    const params = {
      raceId: raceId || selectedRace?.id,
    };
    
    if (!!token) {
      params['token'] = token;
      headers['x-access-token'] = JSON.stringify(token);
    }

    if (skinId) {
      params['operatorId'] = skinId;
    }

    if (limit) {
      params['limit'] = limit;
    }

    const response = yield call(apiGet, 'gameBoxUrl',
      apiRoutes.GET_RACES_LEADERBOARD, params, headers);

    if (response.data.success) {
      if (raceId) {
        const race = races?.find(item => item.id === raceId);
        if (race) {
          yield put(selectRace(race))
        }
        yield put(successRacesLeaderboard({ raceId, leaderBoard: {[raceId]: response?.data?.result?.leaderBoard}, accountResult: {[response?.data?.result?.leaderBoard?.[0]?.race_id]: response?.data?.result?.accountResult} }));
      }
    } else {
      yield put(failRacesLeaderboard(response?.data?.message, raceId));
    }
  } catch (e) {
    console.log(e);
    yield put(failRacesLeaderboard(e, raceId));
  }
}

export default function* watchRacesLeaderboardRequest() {
  while (true) {
    const action = yield take(actionTypes.RACES_LEADERBOARD_REQUEST);
    yield fork(getRacesLeaderboard, action);
  }
}
