import { useRef, useState } from "react";
import PortalContainer from "../../PortalContainer";
import { classNames } from "../../../helpers/classNames";
import { ReactSVG } from "react-svg";
import Button from "../../common/Button";
import "./DialogTemplate.scss";

const DialogTemplate = ({
  children,
  className= "",
  overlay,
  centerOfPage,
  withAnimation,
  cancelButtonText,
  confirmButtonText,
  style,
  onCancel,
  onConfirm,
}) => {
  const [isOpened, setIsOpened] = useState(true);
  const [isClosing, setIsClosing] = useState(false);
  const dialogTemplateRef = useRef(null);

  const handleWithoutAnimation = () => {
    if (onCancel) {
      onCancel();
    } else if (onConfirm) {
      onConfirm();
    }
  };

  const handleAnimationEnd = () => {
    if (!isOpened) {
      if (isClosing) {
        handleWithoutAnimation();
      } else if (onConfirm) {
        onConfirm();
      }
      setIsClosing(false);
    }
  };

  const handleCancelClick = () => {
    if (withAnimation) {
      setIsOpened(false);
      setIsClosing(true);
    } else {
      handleWithoutAnimation();
    }
  };

  const handleConfirmClick = () => {
    if (withAnimation) {
      setIsOpened(false);
    } else if (onConfirm) {
      onConfirm();
    }
  };

  const closeModal = () => {
    if (withAnimation) {
      setIsClosing(true);
      setIsOpened(false);
    } else {
      handleWithoutAnimation();
    }
  };

  const dialogClasses = classNames("dialog-template", {
    [className]: !!className,
    "dialog-template--animate": !!withAnimation,
    "dialog-template--animate--hide": !isOpened,
  });

  return (
    <PortalContainer centerOfPage={centerOfPage} overlay={overlay}>
      <div
        ref={dialogTemplateRef}
        onAnimationEnd={handleAnimationEnd}
        className={dialogClasses}
        style={style}
      >
        <div onClick={closeModal} className="dialog-template__close-wrapper">
          <ReactSVG
            className="dialog-template__close"
            src="/static/images/close.svg"
          />
        </div>
        {children}
        {(cancelButtonText || confirmButtonText) && (
          <div className="dialog-template__buttons-wrapper">
            {cancelButtonText && onCancel && (
              <Button
                className="btn dialog-template__cancel-button"
                variant="tertiary"
                onClick={handleCancelClick}
              >
                {cancelButtonText}
              </Button>
            )}
            {confirmButtonText && onConfirm && (
              <Button
                className="btn dialog-template__confirm-button"
                variant="primary"
                style={{ marginLeft: cancelButtonText && onCancel ? 8 : 0 }}
                onClick={handleConfirmClick}
              >
                {confirmButtonText}
              </Button>
            )}
          </div>
        )}
      </div>
    </PortalContainer>
  );
};

export default DialogTemplate;
