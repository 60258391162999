import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { getCMSResponseRequest } from "../../store/actionCreators/CMSResponse";
import { getDesktopBanners, getMobileBanners, getStrapiUrl } from "../../store/selectors";
import settings from "../../configs/settings";
import { isValidHttpsUrl } from "../../helpers/helpers";
import "./Banner.scss";

const Banner = () => {
  const dispatch = useDispatch();
  const banner = useSelector(isMobile ? getMobileBanners : getDesktopBanners);
  const strapiUrl = useSelector(getStrapiUrl);  

  useEffect(() => {
    dispatch(getCMSResponseRequest());
  }, []);

  return (
    <img
      className="banner"
      alt="banner"
      src={`${strapiUrl && isValidHttpsUrl(strapiUrl) ? strapiUrl : settings?.FILE_STORAGE_ADDRESS}${banner}`}
    />
    
  );
};

export default Banner;
