import { memo, useMemo } from "react";
import { classNames } from "../../helpers/classNames";
import Spinner from "../common/Spinner";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  getAwardPlacement,
  getRace,
  isRacesLeaderboardending,
} from "../../store/selectors";
import { usePrizeDrawing } from "../../hooks/usePrizeDrawing";

const STATIC_HEADER = (t) => [
  { value: t("place") },
  { value: "" },
  { value: "" },
  { value: t("prize") },
];

const PrizepoolTable = ({ id, visibleItems }) => {
  const { t } = useTranslation();
  const pending = useSelector(isRacesLeaderboardending)(id);
  const race = useSelector(getRace)(id);
  const awardPlacement = useSelector(getAwardPlacement)(id);
  const getPrize = usePrizeDrawing(id);
  const header = STATIC_HEADER(t);

  const prizeStructureHeaders = race?.prizeStructure?.headers;
  const noData = !prizeStructureHeaders?.length;

  const visiblePrizeStructureHeaders = useMemo(() => {
    return visibleItems === "all"
      ? prizeStructureHeaders
      : prizeStructureHeaders?.slice(0, visibleItems);
  }, [prizeStructureHeaders, visibleItems]);

  return (
    <div
      className={classNames("table", `table--col-${header.length}`, {
        "table--no-data": noData,
      })}
    >
      {pending && (
        <div className="table__pending">
          <Spinner />
        </div>
      )}
      <div className="table__header">
        <div className="table__inner">
          {header.map((item, index) => (
            <div key={index} className="table__header-item">
              <span className="table__header-item-text">{item.value}</span>
            </div>
          ))}
        </div>
      </div>
      <div className={classNames("table__body", { "table__body--all": visibleItems === "all" })}>
        {visiblePrizeStructureHeaders?.length > 0 &&
          visiblePrizeStructureHeaders.map((item, index) => (
            <div
              key={item?.header_id}
              className={classNames("table__body-row", {
                "table__body-row--first": index === 0,
                "table__body-row--second": index === 1,
                "table__body-row--third": index === 2,
                "table__body-row--even": index % 2 === 0,
                "table__body-row--odd": index % 2 !== 0,
              })}
            >
              <div className="table__body-row-inner">
                <div className="table__body-row-item">
                  <div className="table__body-row-item-inner">
                    <span>
                      {item?.place_to === item?.place_from
                        ? item?.place_from
                        : `${item?.place_from} - ${item?.place_to}`}
                    </span>
                  </div>
                </div>
                <div className="table__body-row-item">
                  <div className="table__body-row-item-inner">
                    <span></span>
                  </div>
                </div>
                <div className="table__body-row-item">
                  <div className="table__body-row-item-inner">
                    <span></span>
                  </div>
                </div>
                <div className="table__body-row-item">
                  <div className="table__body-row-item-inner">
                    <span>{getPrize(awardPlacement?.[item?.place_from])}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default memo(PrizepoolTable);
