import types from "../actionTypes";

export const getCMSResponseRequest = () => ({
  type: types.GET_CMS_RESPONSE_REQUEST,
});

export const getCMSResponseFailure = (e) => ({
  type: types.GET_CMS_RESPONSE_FAILURE,
  exception: e,
});

export const getCMSResponseSucsess = (payload) => ({
  type: types.GET_CMS_RESPONSE_SUCSESS,
  payload,
});
