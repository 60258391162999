import { take, fork, call, put, select } from "redux-saga/effects";

import { apiGet } from "./callApi";

import actionTypes from "../actionTypes";
import { getSkinId, getToken } from "../selectors";
import { failRaces, selectRace, successRaces } from "../actionCreators/races";
import moment from "moment";

import apiRoutes from "../../constants/api/apiRoutes";
import { requestRacesLeaderboard } from "../actionCreators/racesLeaderboard";
import { storeAwardPlacement } from "../actionCreators/awardPlacement";

function* getRaces() {
  try {
    const headers = {};
    const params = {};

    const token = yield select(getToken);
    const skinId = yield select(getSkinId);

    if (!!token) {
      params["token"] = token;
      headers["x-access-token"] = JSON.stringify(token);
    }

    if (skinId) {
      params["operatorId"] = skinId;
    }

    const response = yield call(
      apiGet,
      "gameBoxUrl",
      apiRoutes.GET_RACES,
      token ? { token } : params,
      headers
    );
    if (response?.data?.success) {
      const activeRaces =
        response.data?.result?.races?.filter((item) => item?.isActive) || [];
      const priviousRacesFirstItem = response.data?.result?.races?.find(
        (race) => !race.isActive && moment(race.expire_time).isBefore(moment())
      );

      yield put(successRaces(response.data?.result?.races));
      if (activeRaces.length) {
        for (let activeRace of activeRaces) {
          yield put(
            requestRacesLeaderboard({
              limit: activeRace?.accountsCount || 20,
              raceId: activeRace?.id,
            })
          );
          yield put(
            storeAwardPlacement({
              id: activeRace?.id,
              awardHeaders: activeRace?.prizeStructure?.headers,
            })
          );
        }
        if (priviousRacesFirstItem) {
          yield put(
            storeAwardPlacement({
              id: priviousRacesFirstItem?.id,
              awardHeaders: priviousRacesFirstItem?.prizeStructure?.headers,
            })
          );
        }
        yield put(selectRace(activeRaces[0]));
      }
    } else {
      yield put(failRaces(response?.data?.message));
    }
  } catch (e) {
    yield put(failRaces(e));
  }
}

export default function* watchRacesRequest() {
  while (true) {
    yield take([
      actionTypes.RACES_REQUEST,
      actionTypes.JOIN_TO_RACE_SUCCESS,
      actionTypes.LOGIN_SET_QUERY_PARAMS,
      actionTypes.LOGOUT_SET_QUERY_PARAMS,
    ]);
    yield fork(getRaces);
  }
}
