import { all, call } from "redux-saga/effects";

import watchRacesRequest from "./races";
import watchJoinToRaceRequest from "./joinToRace";
import watchRacesLeaderboardRequest from "./racesLeaderboard";
import watchGetCMSResponseRequest from "./CMSResponse";
import watchGetRaceMissionTemplatesRequest from "./raceMissionTemplates";

function* rootSaga() {
  yield all([
    call(watchRacesRequest),
    call(watchJoinToRaceRequest),
    call(watchRacesLeaderboardRequest),
    call(watchGetCMSResponseRequest),
    call(watchGetRaceMissionTemplatesRequest),
  ]);
}

export default rootSaga;
