import { memo } from "react";
import { isNumber } from "../../helpers/helpers";
import "./Skeleton.scss";

const Skeleton = ({ radius, count, width, height, space, rounded, style }) => {
  const renderSkeletonItem = (index) => (
    <div
      key={index}
      style={{
        width:
          isNumber(count) && count > 1 && isNumber(space) && space > 0
            ? `calc(100% / ${count})`
            : width || 20,
        height: height || 20,
        borderRadius: rounded ? '50%' : radius ?? '0',
        marginLeft:
          isNumber(count) && count > 1 && isNumber(space) && space > 0
            ? index > 0
              ? space
              : 0
            : 0,
      }}
      className="skeleton__item"
    />
  );
  return (
    <div style={style} className="skeleton">
      {isNumber(count) && count > 1 ? (
        <div className="skeleton__list">
          {Array(count)
            .fill()
            .map((_, index) => renderSkeletonItem(index))}
        </div>
      ) : (
        renderSkeletonItem(1)
      )}
    </div>
  );
};

export default memo(Skeleton);
