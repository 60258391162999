import { useCallback, useState } from "react";
import { isMobile } from "react-device-detect";
import { RaceView } from "../RaceView";
import MobileRace from "./MobileRace";
import DesktopRace from "./DesktopRace";
import settings from "../../configs/settings";

const INITIAL_VIEW = "ongoing";

const Race = () => {
  const [currentView, setCurrentView] = useState(INITIAL_VIEW);

  const handleCurrentViewChange = useCallback((view) => {
    if (settings?.howToPlayExternalLink && view === 'howToPlay') {
      window.open(settings.howToPlayExternalLink, '_blank');
      return;
    }
    setCurrentView(view);
  }, []);

  const SelectedView = RaceView.get(currentView || INITIAL_VIEW);

  const Component = isMobile ? MobileRace : DesktopRace;
  return (
    <Component
      currentView={currentView}
      SelectedView={SelectedView}
      handleCurrentViewChange={handleCurrentViewChange}
    />
  );
};

export default Race;
