const settings = {
  gameBoxUrl: "https://tslotsgameboxapi.draftplaza.com",
  FILE_STORAGE_ADDRESS: "https://strapi4betpassion.draftplaza.com",
  theme: {
    hasThemeSwitcher: false,
    style: "lightGreen", // dark, lightGreen
  },
  howToPlayExternalLink: "https://www.betnow.it/promozioni/casino/casino-race/come-funziona",
  externalLinkPlayButton: "https://www.betnow.it/casino",
};

export default settings;
