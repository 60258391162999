import actionTypes from '../actionTypes';
import { call, cancel, fork, put, take } from "redux-saga/effects";
import { apiGet } from './callApi';
import apiRoutes from '../../constants/api/apiRoutes';
import { getRaceMissionTemplatesFailure, getRaceMissionTemplatesSucsess } from '../actionCreators/raceMissionTemplates';

function* processGetRaceMissionTemplatesRequest({ raceId, missionTemplateIds }) {
  try {
    const params = { raceId };

    if (missionTemplateIds?.length) {
      params['missionTemplateIds'] = missionTemplateIds;
    }

    const response = yield call(
      apiGet,
      "gameBoxUrl",
      apiRoutes.GET_RACE_MISSION_TEMPLATES,
      params,
    );

    if (response?.data?.success) {
      yield put(getRaceMissionTemplatesSucsess(response.data?.result?.missionTemplates));
    }
  } catch (e) {
    yield put(getRaceMissionTemplatesFailure(e));
  }
}

export default function* watchGetRaceMissionTemplatesRequest() {
  let forked = null;
  while (true) {
    const action = yield take(actionTypes.GET_RACE_MISSION_TEMPLATES_REQUEST);
    if (forked) yield cancel(forked);
    forked = yield fork(processGetRaceMissionTemplatesRequest, action);
  }
}