import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import ReactDOM from 'react-dom';

import './PortalContainer.scss';

const createOverlay = (container) => {
  const overlayElement = document.createElement('div');
  overlayElement.classList.add('portal-container__overlay');
  container.appendChild(overlayElement);
};

const createContainer = (
  id,
  className,
  overlay,
  centerOfPage,
) => {
  const portalContainer = document.createElement('div');
  portalContainer.classList.add('portal-container');
  if (overlay) {
    createOverlay(portalContainer);
  }
  if (centerOfPage) {
    portalContainer.classList.add('portal-container--center');
  }
  if (className) {
    portalContainer.classList.add(className);
  }
  if (isMobile) {
    portalContainer.classList.add('mobile');
  }
  if (id) {
    portalContainer.id = id;
  }
  return portalContainer;
};

const PortalContainer = ({
  children,
  id,
  selector,
  className,
  overlay,
  returnChildren,
  centerOfPage,
}) => {
  const [container, setContainer] = useState(null);

  useEffect(() => {
    if (returnChildren) return;

    const createdContainer = createContainer(id, className, overlay, centerOfPage);
    setContainer(createdContainer);

    const parentElement = selector ? document.querySelector(selector) : document.body;

    if (parentElement) {
      parentElement.appendChild(createdContainer);

      return () => {
        parentElement.removeChild(createdContainer);
      };
    }

    return () => {
      console.log('No parent element to remove from.');
    };
  }, [returnChildren, id, selector, className]);

  return returnChildren ? <>{children}</> : container && ReactDOM.createPortal(children, container);
};

export default PortalContainer;
