import actionTypes from '../actionTypes';

export function requestJoinToRace(raceId) {
  return {
    type: actionTypes.JOIN_TO_RACE_REQUEST,
    raceId
  };
}

export function successJoinToRace(payload) {
  return {
    type: actionTypes.JOIN_TO_RACE_SUCCESS,
    payload,
  };
}

export function failJoinToRace(e) {
  return {
    type: actionTypes.JOIN_TO_RACE_FAILURE,
    exception: e,
  };
}

export function updateJoinToRacePendingStatus() {
  return {
    type: actionTypes.UPDATE_JOIN_TO_RACE_PENDING_STATUS,
  };
}
