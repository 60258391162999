import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import {
  getRacesAccountResult,
} from "../../store/selectors";
import { makeRaceInActive } from "../../store/actionCreators/races";
import { TABLE_VIEW } from "../../constants/tableView";
import MobileLeaderboardAndPrizepool from "./MobileLeaderboardAndPrizepool";
import DesktopLeaderboardAndPrizepool from "./DesktopLeaderboardAndPrizepool";
import { useFormatCurrency } from "../../hooks/useFormatCurrency";

const VISIBLE_ITEMS_COUNT = 5;
const CURRENT_TABLE_INITIAL = "leaderboard";

const LeaderboardAndPrizepool = ({ item }) => {
  const dispatch = useDispatch();
  const [currentTable, setCurrentTable] = useState(CURRENT_TABLE_INITIAL);
  const [visibleItems, setVisibleItems] = useState(VISIBLE_ITEMS_COUNT);
  const formatCurrency = useFormatCurrency();

  const accountResult = useSelector(getRacesAccountResult)(item.id, formatCurrency);
  const hasAccountResult =
    Object.values(accountResult).filter(Boolean).length > 1;
  const handleShowMore = useCallback(() => {
    setVisibleItems("all");
  }, []);

  const handleCurrentTableChange = useCallback((view) => {
    setVisibleItems(VISIBLE_ITEMS_COUNT);
    setCurrentTable(view);
  }, []);

  const handleCountDownAction = useCallback(() => {
    dispatch(makeRaceInActive(item));
  }, [item]);

  const TableView = TABLE_VIEW.get(currentTable);

  if (!item || !Object.keys(item)?.length) {
    return null;
  }

  const Component = isMobile
    ? MobileLeaderboardAndPrizepool
    : DesktopLeaderboardAndPrizepool;
  return (
    <Component
      visibleItems={visibleItems}
      item={item}
      currentTable={currentTable}
      TableView={TableView}
      handleShowMore={handleShowMore}
      handleCurrentTableChange={handleCurrentTableChange}
      handleCountDownAction={handleCountDownAction}
      hasAccountResult={hasAccountResult}
    />
  );
};

export default LeaderboardAndPrizepool;
