import { memo } from "react";
import { classNames } from "../../helpers/classNames";
import "./Tabs.scss";

const Tabs = ({ tabs, selectedTab, onChange, variant, backgroundColor }) => {
  if (!tabs || !Array.isArray(tabs) || tabs?.length <= 0) {
    return null;
  }
  return (
    <div style={{ backgroundColor: backgroundColor ?? undefined }} className={classNames('tabs', `tabs--${variant || 'primary'}` )}>
      {tabs.map((tab) => (
        <div
          key={tab.value}
          onClick={() => onChange(tab.value)}
          className={classNames("tabs__tab-item", {
            "tabs__tab-item--selected": tab.value === selectedTab,
          })}
        >
          <span className="tabs__tab">{tab.label}</span>
        </div>
      ))}
    </div>
  );
};

export default memo(Tabs);
