import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import GameCard from "../../../GameCard";
import Tabs from "../../../Tabs";
import MyAccountResult from "../../../MyAccountResult";
import Button from "../../../common/Button";
import "./MobilePreviousRacesView.scss";

const VISIBLE_ITEMS_COUNT = 5;

const MobilePreviousRacesView = ({
  handleCurrentTableChange,
  currentTable,
  previousRaces,
  TableView,
}) => {
  return (
    <div className="mobile-previous-races-view">
      <div className="mobile-previous-races-view__race-list">
        {previousRaces.map((race) => (
          <MobilePreviosItem
            key={race?.id}
            race={race}
            handleCurrentTableChange={handleCurrentTableChange}
            currentTable={currentTable}
            TableView={TableView}
          />
        ))}
      </div>
    </div>
  );
};

export default MobilePreviousRacesView;

const MobilePreviosItem = ({
  race,
  handleCurrentTableChange,
  currentTable,
  TableView,
}) => {
  const { t } = useTranslation();
  const [visibleItems, setVisibleItems] = useState(VISIBLE_ITEMS_COUNT);
  const [selectedRaceId, setSelectedRaceId] = useState(null);

  const handleShowMore = useCallback(
    () => setVisibleItems('all'),
    []
  );

  const handleSetSelectedId = useCallback(
    (raceId) => {
      if (raceId !== selectedRaceId) {
        setSelectedRaceId(raceId);
      }
    },
    [selectedRaceId]
  );
  return (
    <div className="mobile-previous-races-view__race-item">
      <GameCard
        cb={handleSetSelectedId}
        selectedRaceId={selectedRaceId}
        race={race}
        isCompleted
      >
        <div className="mobile-previous-races-view__body">
          <div className="mobile-previous-races-view__tab-my-account-result-table-view-wrapper">
            <div className="mobile-previous-races-view__tabs-wrapper">
              <Tabs
                tabs={[
                  { label: t("leaderboard"), value: "leaderboard" },
                  { label: t("prizepool"), value: "prizepool" },
                ]}
                selectedTab={currentTable}
                onChange={handleCurrentTableChange}
                variant="secondary"
                backgroundColor="var(--BG-Surface-3, #2E3138)"
              />
            </div>
            <div className="mobile-previous-races-view__my-account-result-wrapper">
              <MyAccountResult id={race.id} />
            </div>
            <div className="mobile-previous-races-view__table-view-wrapper">
              <TableView id={race.id} visibleItems={visibleItems} />
            </div>
          </div>
          <div className="mobile-previous-races-view__actions-wrapper">
            <Button
              variant="secondary"
              disabled={visibleItems === 'all'}
              onClick={handleShowMore}
            >
              {t("showMore")}
            </Button>
          </div>
        </div>
      </GameCard>
    </div>
  );
};
