import { useEffect } from "react";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { getLang, getLanguage, getUpcomingRaces } from "../../../store/selectors";
import HorizontalScroll from "../../common/HorizontalScroll";
import GameCard from "../../GameCard";
import "./UpcomingView.scss";

const UpcomingView = () => {
  const upcomingRaces = useSelector(getUpcomingRaces);
  const LANGUAGE = useSelector(getLanguage);
  const LANG = useSelector(getLang);
  const language = LANGUAGE || LANG;

  useEffect(() => {
    if (language) moment.locale(language);
  }, [language]);

  if (!upcomingRaces?.length) return null;

  return (
    <div className="upcoming-view">
      <div className="upcoming-view__list">
        <HorizontalScroll returnChildren={isMobile} gap={20} itemWidth={545}>
          {upcomingRaces.map((race, index) => (
            <div key={index} className="upcoming-view__item">
              <GameCard race={race} />
            </div>
          ))}
        </HorizontalScroll>
      </div>
    </div>
  );
};

export default UpcomingView;
