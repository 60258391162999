const createExternalPrizeDetail = (prize) => ({
  type: 'externalPrize',
  value: {
      description: prize.description || "No description available"
  }
});

const createMissionDetail = (prize) => ({
  type: 'mission',
  value: {
      description: prize.description || "mission",
      templateId: prize.templateId,
      icon: {
          name: "icon-info",
          clickable: true
      }
  }
});

const createMoneyDetail = (prize) => ({
  type: 'money',
  value: {
      amount: prize.amount || 0,
      currency: prize.currency || ""
  }
});

const getAwardDetails = (prize) => {
  switch (prize?.type) {
      case "externalPrize":
          return createExternalPrizeDetail(prize);
      case "mission":
          return createMissionDetail(prize);
      case "money":
          return createMoneyDetail(prize);
      default:
          return prize || {};
  }
};

export const mapAwardsToPlacement = (array) => {
  if (!array) return {};
  return array.reduce((acc, header) => {
      for (let i = header.place_from; i <= header.place_to; i++) {
          acc[i] = getAwardDetails(header.prize);
      }
      return acc;
  }, {});
};
