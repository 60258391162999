import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { classNames } from "../../helpers/classNames";
import { THEME, THEME_OPTIONS } from "../../constants/theme";
import settings from "../../configs/settings";
import { changeTheme } from "../../helpers/changeTheme";
import "./ThemeSwitcher.scss";

const ThemeSwitcher = ({ openToTopOfList }) => {
  const { t } = useTranslation();
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  useOutsideClick(dropdownRef, setIsOpen);

  
  useEffect(() => {
    if (!selectedOption) {
      const themeVariant = THEME_OPTIONS?.find((option) => option?.value === settings?.theme?.style)
      ?.value || THEME_OPTIONS[0].value
      setSelectedOption(themeVariant);
    }
  }, [selectedOption]);

  const handleOptionClick = useCallback((option) => {
    setSelectedOption(option.value);
    changeTheme(THEME[option.value]);
  }, []);

  const bodyItems = useMemo(
    () =>
      THEME_OPTIONS.map((option, index) => (
        <div
          key={index}
          className={classNames("theme-switcher__body-item", {
            "theme-switcher__body-item--disabled": !!option.disabled,
          })}
          onClick={() => handleOptionClick(option)}
        >
          <span className="theme-switcher__body-item-text">{option.label}</span>
          <div
            className={`theme-switcher__body-item-theme-image theme-switcher__body-item-theme-image--${option.value}`}
          />
        </div>
      )),
    [THEME_OPTIONS, handleOptionClick, selectedOption]
  );

  return (
    <>
      <div
        className={classNames("theme-switcher", {
          "theme-switcher--opened": isOpen,
          "theme-switcher--open-to-top-of-list": !!openToTopOfList,
        })}
        ref={dropdownRef}
      >
        <div
          className="theme-switcher__header"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <span className="theme-switcher__header-text">{t("raceTheme")}</span>
          <i className="theme-switcher__header-icon icon-arrow-right" />
        </div>
        {isOpen && (
          <div className="theme-switcher__body">
            <div className="theme-switcher__body-inner">{bodyItems}</div>
          </div>
        )}
      </div>
    </>
  );
};

export default ThemeSwitcher;
