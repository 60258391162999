import { useCallback } from "react";
import { useSelector } from "react-redux";
import accounting from "accounting";
import Coins from "../components/CoinBalance/Coins";
import { getCurrencySymbol } from "../helpers/format";
import { isShowFormattedAmountWithDot } from "../store/selectors";

export const useFormatCurrency = () => {
  const showFormattedAmountWithDot = useSelector(isShowFormattedAmountWithDot);

  return useCallback((amount, currency) => {
    if ((currency === "FPP" || currency === "GOS") && amount >= 0) {
      return <Coins val={amount} type={currency} />;
    }
    if (showFormattedAmountWithDot) {
      return accounting.formatMoney(amount, getCurrencySymbol(currency) + " ", {
        precision: 0,
      }).replace(/,/g, '.');
    } else {
      return accounting.formatMoney(amount, getCurrencySymbol(currency) + " ");
    }
  }, []);
};
