const actionTypes = {
  RACES_REQUEST: 'RACES_REQUEST',
  RACES_FAILURE: 'RACES_FAILURE',
  RACES_SUCCESS: 'RACES_SUCCESS',
  MAKE_RACE_INACTIVE: 'MAKE_RACE_INACTIVE',

  JOIN_TO_RACE_REQUEST: 'JOIN_TO_RACE_REQUEST',
  JOIN_TO_RACE_FAILURE: 'JOIN_TO_RACE_FAILURE',
  JOIN_TO_RACE_SUCCESS: 'JOIN_TO_RACE_SUCCESS',

  LOGOUT_SET_QUERY_PARAMS: 'LOGOUT_SET_QUERY_PARAMS',
  LOGIN_SET_QUERY_PARAMS: 'LOGIN_SET_QUERY_PARAMS',
  SET_QUERY_PARAMS: 'SET_QUERY_PARAMS',

  RACES_LEADERBOARD_REQUEST: 'RACES_LEADERBOARD_REQUEST',
  RACES_LEADERBOARD_FAILURE: 'RACES_LEADERBOARD_FAILURE',
  RACES_LEADERBOARD_SUCCESS: 'RACES_LEADERBOARD_SUCCESS',
  RACES_LEADERBOARD_RESET: 'RACES_LEADERBOARD_RESET',

  SELECT_RACE: 'SELECT_RACE',

  UPDATE_JOIN_TO_RACE_PENDING_STATUS: 'UPDATE_JOIN_TO_RACE_PENDING_STATUS',
  GET_CMS_RESPONSE_REQUEST: 'GET_CMS_RESPONSE_REQUEST',
  GET_CMS_RESPONSE_FAILURE: 'GET_CMS_RESPONSE_FAILURE',
  GET_CMS_RESPONSE_SUCSESS: 'GET_CMS_RESPONSE_SUCSESS',

  GET_RACE_MISSION_TEMPLATES_REQUEST: 'GET_RACE_MISSION_TEMPLATES_REQUEST',
  GET_RACE_MISSION_TEMPLATES_FAILURE: 'GET_RACE_MISSION_TEMPLATES_FAILURE',
  GET_RACE_MISSION_TEMPLATES_SUCSESS: 'GET_RACE_MISSION_TEMPLATES_SUCSESS',
  GET_RACE_MISSION_TEMPLATES_RESET: 'GET_RACE_MISSION_TEMPLATES_RESET',

  OPEN_DIALOG: "OPEN_DIALOG",
  CLOSE_DIALOG: "CLOSE_DIALOG",

  STORE_AWARD_PLACEMENT: 'STORE_AWARD_PLACEMENT',
};

export default actionTypes;
