import LeaderBoardTable from "./LeaderBoardTable";
import "./LeaderBoard.scss";

const LeaderBoard = ({ visibleItems, id }) => {
  return (
    <div className="leaderboard">
      <LeaderBoardTable visibleItems={visibleItems} id={id} />
    </div>
  );
};

export default LeaderBoard;
