import { take, fork, call, cancel, put } from "redux-saga/effects";
import apiRoutes from "../../constants/api/apiRoutes";
import types from "../actionTypes";
import {
  getCMSResponseFailure,
  getCMSResponseSucsess,
} from "../actionCreators/CMSResponse";
import { apiGetStrapi } from "./callApi";

const LANGUAGES = {
  en: 'en',
  es: 'es',
  it: 'it',
  tr: 'tr',
  'pt-BR': 'pt',
  'fr-FR': 'fr',
  'ar-001': 'ar',
};

function* processGetCMSResponseRequest() {
  try {
    const response = yield call(
      apiGetStrapi,
      "FILE_STORAGE_ADDRESS",
      apiRoutes.GET_CMS_RESPONSE,
      {}
    );
    if (response?.data) {
      const result = {
        banners: {
          desktopBannerURL:
            response.data?.data?.attributes?.desktopMainLobbyBanner?.data?.map(
              (u) => u?.attributes?.url
            )?.[0],
          mobileBannerURL:
            response.data?.data?.attributes?.mobileMainLobbyBanner?.data?.map(
              (u) => u?.attributes?.url
            )?.[0],
        },
        [LANGUAGES?.[response.data?.data?.attributes?.locale]] : {
          howToPlay: {
            desktopHowToPlay: response.data?.data?.attributes?.desktopHowToPlay,
            mobileHowToPlay:  response.data?.data?.attributes?.mobileHowToPlay,
          }
        },
        ...response.data?.data?.attributes?.localizations?.data?.reduce((acc, item) => {
          acc[LANGUAGES[item?.attributes.locale]] = {
            howToPlay: {
              desktopHowToPlay: item?.attributes?.desktopHowToPlay,
              mobileHowToPlay:  item?.attributes?.mobileHowToPlay,
            }
          }
          return acc;
        }, {}),
      };
      yield put(getCMSResponseSucsess(result));
    }
  } catch (e) {
    yield put(getCMSResponseFailure(e));
  }
}

export default function* watchGetCMSResponseRequest() {
  let forked = null;
  while (true) {
    yield take(types.GET_CMS_RESPONSE_REQUEST);
    if (forked) yield cancel(forked);
    forked = yield fork(processGetCMSResponseRequest);
  }
}
