import "./index.scss";
import coinIcon from "./coin.svg";
import gosIcon from "./gos.svg";

const Coins = ({ val, type }) => {
  return (
    <div className="coinBalance">
      <img src={type === "FPP" ? coinIcon : gosIcon} alt="" />
      <span>{parseFloat(val).toFixed()}</span>
    </div>
  );
};
export default Coins;
