import { useState, useEffect, memo } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import { getRemainingTimeUntilMsTimestamp } from "../../helpers/countdownTimer";
import { classNames } from "../../helpers/classNames";

import "./index.scss";

const CountDownTimer = ({
  countdownTimestampMs,
  variant,
  size,
  withoutLabels,
  withDots,
  fullDate,
  withSkeleton,
  countDownCallback
}) => {
  const { t } = useTranslation();

  const [remainingTime, setRemainingTime] = useState(null);

  const buttonClassNames = classNames({
    "countdown-timer": true,
    "countdown-timer--mobile": isMobile,
    [`countdown-timer--${variant}`]: variant,
    [`countdown-timer--${size}`]: size,
    "countdown-timer--skeleton": !remainingTime
  });

  const updateRemainingTime = (countdown) => {
    setRemainingTime(getRemainingTimeUntilMsTimestamp(countdown));
  };

  let REMAINING_TIMES = [
    { label: "hh", value: 0 },
    { label: "mm", value: 0 },
    { label: "ss", value: 0 },
  ];
  if (fullDate) REMAINING_TIMES.unshift({ label: "dd", value: 0 });

  const { days, hours, minutes, seconds } = remainingTime || {};
  REMAINING_TIMES = [
    // { label: "Days", value: days },
    { label: "hh", value: hours },
    { label: "mm", value: minutes },
    { label: "ss", value: seconds },
  ];

  if (days) {
    if (days !== '00') {
      REMAINING_TIMES.unshift({ label: "dd", value: days },);
    }
  }


  useEffect(() => {
    if (days === "00") {
      const intervalId = setInterval(() => {
        updateRemainingTime(countdownTimestampMs);
      }, 1000);
      return () => clearInterval(intervalId);
    } else {
      updateRemainingTime(countdownTimestampMs);
    }
  }, [countdownTimestampMs, days]);

  if(days == "00" && hours == "00" && minutes == "00" && seconds == "00") {
    setRemainingTime(null);
    if(countDownCallback) {
      countDownCallback();
    }
    return;
  }

  if (!remainingTime && withSkeleton) return <div className={buttonClassNames}>
    {REMAINING_TIMES.map(({ label }) => (
      <div key={label} className="countdown-timer__col">
        <div className="countdown-timer__remaining-time-wrapper">
          <span className="countdown-timer__remaining-time">{ }</span>
        </div>
        <span className="countdown-timer__label">{t(label)}</span>
      </div>
    ))}
  </div>;


  return (
    <div className={buttonClassNames}>
      {days !== '00' ? <div>{days} {t('days')}</div> :
        REMAINING_TIMES.map(({ label, value }) => (
          <div key={label} className="countdown-timer__col">
            <div className="countdown-timer__remaining-time-wrapper">
              <span className="countdown-timer__remaining-time">{value} {withDots && label !== 'ss' ? <span>{':'}</span> : null}</span>
            </div>
            {withoutLabels ?
              null
              :
              <span className="countdown-timer__label">{t(label)}</span>
            }
          </div>
        ))
      }
    </div>
  );
};

export default memo(CountDownTimer);