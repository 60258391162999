export const THEME = {
  dark: {
    "--BG-Surface-1": "#171922",
    "--BG-Surface-2": "#1F212C",
    "--BG-Surface-3": "#2E3138",
    "--Button-Secondary": "#2E3138",
    "--Button-Secondary_hover": "#434752",
    "--Button-Secondary_disable": "#282A2F",
    "--Button-Primary": "#0F5903",
    "--Button-Primary_hover": "#147C03",
    "--Button-Primary_disable": "#0E3F07",
    "--Border-primary": "#2E3138",
    "--Button-Border-primary": "#2E3138",
    "--Border-Hover": "#DE9F00",
    "--Border-Selected": "#FFBF1E",
    "--Text-primary": "#FFF",
    "--Text-secondary": "#AFAFAF",
    "--Text-Brand-text": "#FFBF1E",
    "--Text-Disable": "#958E8E",
  },
  lightGreen: {
    "--BG-Surface-1": "#ffffff",
    "--BG-Surface-2": "#F5F5F5",
    "--BG-Surface-3": "#DEDEDE",
    "--Button-Secondary": "#9E9E9E",
    "--Button-Secondary_hover": "#434752",
    "--Button-Secondary_disable": "#282A2F",
    "--Button-Primary": "#54AF3A",
    "--Button-Primary_hover": "#147C03",
    "--Button-Primary_disable": "#0E3F07",
    "--Border-primary": "#2E3138",
    "--Button-Border-primary": "#CB1014",
    "--Border-Hover": "#CB1014",
    "--Border-Selected": "#FF0005",
    "--Text-primary": "#616161",
    "--Text-secondary": "#616161",
    "--Text-Brand-text": "#CB1014",
    "--Text-Disable": "#958E8E",
  },
};

export const THEME_OPTIONS = [
  { label: "Dark them", value: "dark" },
  { label: "Green theme", value: "lightGreen" },
];
