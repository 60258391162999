import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { openDialog } from "../store/actionCreators/dialogManager";

export const useMissionInfoClick = (id) => {
  const dispatch = useDispatch();

  return useCallback(
    (templateId) => {
      if (!templateId || !id) return;
      dispatch(openDialog({ dialogType: "missionInfo", missionId: templateId, raceId: id }));
    },
    [dispatch, id]
  );
};
