import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DialogTemplate from "../DialogTemplate";
import { closeDialog } from "../../../store/actionCreators/dialogManager";
import MissionCard from "../../MissionCard";
import {
  getRaceMissionTemplatesRequest,
  getRaceMissionTemplatesReset,
} from "../../../store/actionCreators/raceMissionTemplates";
import {
  getRaceMissionTemplate,
  isRaceMissionTemplatesPending,
} from "../../../store/selectors";
import Skeleton from "../../Skeleton";
import "./MissionInfo.scss";

const MissionInfo = ({ raceId, missionId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(closeDialog("missionInfo"));
  }, []);
  const mission = useSelector(getRaceMissionTemplate)(missionId);
  const pending = useSelector(isRaceMissionTemplatesPending);

  useEffect(() => {
    return () => {
      dispatch(getRaceMissionTemplatesReset());
    };
  }, []);

  useEffect(() => {
    if (missionId && raceId) {
      dispatch(
        getRaceMissionTemplatesRequest({
          raceId,
          missionTemplateIds: [missionId],
        })
      );
    }
  }, [missionId, raceId]);

  return (
    <DialogTemplate
      className="mission-info-dialog"
      onCancel={onClose}
      overlay
      centerOfPage
      style={{ maxWidth: "770px", width: "100%", margin: "0 20px" }}
    >
      <div className="mission-info">
        <div className="mission-info__header">
          <div className="mission-info__header-img" />
          <span className="mission-info__header-description">
            {t('funBonus')}
          </span>
        </div>
        <div className="mission-info__body">
          <div className="mission-info__text-wrapper">
            <span className="mission-info__text-title">
              {t("missionInfoDialog.text-1.title")}
            </span>
            <span className="mission-info__text-description">
              {t("missionInfoDialog.text-1.description")}
            </span>
          </div>
          <div className="mission-info__mission-card-text-wrapper">
            <div className="mission-info__mission-card-wrapper">
              {pending && !mission && (
                <Skeleton width="100%" radius={8} height={222} />
              )}
              {!pending && !mission && (
                <div className="mission-info__mission-not-found">
                  <span>{t("missionNotFound")}</span>
                </div>
              )}
              {!pending && mission && <MissionCard mission={mission} />}
            </div>
            <div className="mission-info__text-wrapper">
              <span className="mission-info__text-title">
                {t("missionInfoDialog.text-2.title")}
              </span>
              <span className="mission-info__text-description">
                {t("missionInfoDialog.text-2.description")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </DialogTemplate>
  );
};

export default MissionInfo;
