import actionTypes from '../actionTypes';

export const openDialog = (dialog) => ({
  type: actionTypes.OPEN_DIALOG,
  payload: dialog,
});

export const closeDialog = (dialogType) => ({
  type: actionTypes.CLOSE_DIALOG,
  payload: dialogType,
});
