import React from 'react';
import { useTranslation } from 'react-i18next';
import gitVersion from '../../_git_commit';
import './styles.scss';

const gitInfo = gitVersion?.logMessage ? gitVersion.logMessage : 'version is not defined for windows dev';

const Version = () => {
  const {t} = useTranslation();

  return (
    <div className="version">{t(`version`)}: {gitInfo}</div>
  );
};
export default Version;

