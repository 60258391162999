import HowToPlayView from "./HowToPlayView";
import OngoingView from "./OngoingView";
import PreviousRacesView from "./PreviousRacesView";
import UpcomingView from "./UpcomingView";

export const RaceView = new Map([
  ["ongoing", OngoingView],
  ["upcoming", UpcomingView],
  ["previousRaces", PreviousRacesView],
  ["howToPlay", HowToPlayView],
]);
