import { ReactSVG } from "react-svg";
import { useMissionInfoClick } from "./useMissionInfoClick";
import { useFormatCurrency } from "./useFormatCurrency";

export const usePrizeDrawing = (id) => {
  const onMissionRequest = useMissionInfoClick(id);
  const formatCurrency = useFormatCurrency();
  const onItemClick = (item) => {
    if (!item) return;
    onMissionRequest(item);
  };
  
  return (prize) => {
    if (!prize) return "";
    const { type, value } = prize;
    switch (type) {
      case "externalPrize":
        return value?.description || "";
      case "mission":
        return (
          <div className="table__body-row-item-inner">
            <span>{value?.description}</span>
            {value?.icon && (
              <div
                onClick={
                  value.icon?.clickable && value?.templateId
                    ? () => onItemClick(value?.templateId)
                    : null
                }
                className="table__body-row-item-icon"
              >
                <ReactSVG src={`/static/images/${value.icon.name}.svg`} />
              </div>
            )}
          </div>
        );
      case "money":
        return formatCurrency(value?.amount || 0, value?.currency || "");
      default:
        return "";
    }
  };
};
