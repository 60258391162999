import actionTypes from '../actionTypes';

export function requestRacesLeaderboard({ limit, raceId }) {
  return {
    type: actionTypes.RACES_LEADERBOARD_REQUEST,
    limit,
    raceId,
  };
}

export function successRacesLeaderboard(payload) {
  return {
    type: actionTypes.RACES_LEADERBOARD_SUCCESS,
    payload,
  };
};

export function resetRacesLeaderboard() {
  return {
    type: actionTypes.RACES_LEADERBOARD_RESET,
  };
}

export function failRacesLeaderboard(e, raceId) {
  return {
    type: actionTypes.RACES_LEADERBOARD_FAILURE,
    exception: e,
    raceId,
  };
}
