import types from "../actionTypes";

export const getRaceMissionTemplatesRequest = ({ raceId, missionTemplateIds }) => ({
  type: types.GET_RACE_MISSION_TEMPLATES_REQUEST,
  raceId,
  missionTemplateIds,
});

export const getRaceMissionTemplatesFailure = (e) => ({
  type: types.GET_RACE_MISSION_TEMPLATES_FAILURE,
  exception: e,
});

export const getRaceMissionTemplatesSucsess = (payload) => ({
  type: types.GET_RACE_MISSION_TEMPLATES_SUCSESS,
 payload,
});

export const getRaceMissionTemplatesReset = () => ({
  type: types.GET_RACE_MISSION_TEMPLATES_RESET,
});
