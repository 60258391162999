import { call, select } from "redux-saga/effects";
import axios from "axios";

import settings from "../../configs/settings.js";
import { getStrapiUrl } from "../selectors.js";
import { isValidHttpsUrl } from "../../helpers/helpers.js";

const GET = "GET";
const POST = "POST";

export default function* callApi(url, path, method, options, headers) {
  let token = null;

  const to = `${url}${path}`;
  const axiosConfig = { method, url: to };
  const params = { ...options };

  const hs = headers || {};

  if (params.token || token) {
    hs["x-access-token"] = params.token || token;
    delete params.token;
  }

  axiosConfig.headers = hs;

  if (options) axiosConfig[method === "GET" ? "params" : "data"] = params;

  const result = yield call(axios, axiosConfig);

  return result;
}

export function* apiGet(api, to, params, headers, flags) {
  return yield call(callApi, settings[api], to, GET, params, headers, flags);
}
export function* apiGetStrapi(api, to, params, headers, flags) {
  const strapiUrl = yield select(getStrapiUrl);  
  return yield call(callApi, (strapiUrl && isValidHttpsUrl(strapiUrl) ? strapiUrl : settings[api]), to, GET, params, headers, flags);
}

export function* apiPost(api, to, params, headers, flags) {
  return yield call(callApi, settings[api], to, POST, params, headers, flags);
}
