import { memo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { getAwardPlacement, getLang, getLanguage, getRacesAccountResult } from "../../store/selectors";
import { isMobile } from "react-device-detect";
import { usePrizeDrawing } from "../../hooks/usePrizeDrawing";
import "./MyAccountResult.scss";

const MyAccountResult = ({ id }) => {
  const { t } = useTranslation();
  const accountResult = useSelector(getRacesAccountResult)(id);
  const awardPlacement = useSelector(getAwardPlacement)(id);
  const LANGUAGE = useSelector(getLanguage);
  const LANG = useSelector(getLang);
  const lang = LANGUAGE || LANG;
  const getPrize = usePrizeDrawing(id);
  const isPT = lang === "pt";

  return (
    <>
      {accountResult &&
      Object.values(accountResult).filter(Boolean).length > 1 ? (
        <div className="my-account-result">
          <div className="my-account-result__row">
            <div className="my-account-result__rank-you-wrapper">
              <div className="my-account-result__rank-wrapper">
                <span className="my-account-result__rank">
                  {accountResult.rank}
                </span>
              </div>
              <span className="my-account-result__you">{t("you")}</span>
            </div>
          </div>
          <div className="my-account-result__row">
            <div className="my-account-result__item-icon-wrapper">
              {!isPT && (
                <span className="my-account-result__item-text">
                  {t("score")}
                </span>
              )}
              {(!isMobile || isPT) && (
                <div className="my-account-result__item-icon">
                  <ReactSVG src="/static/images/icon-score.svg" />
                </div>
              )}
              <span className="my-account-result__item">
                {accountResult.points}
              </span>
            </div>
          </div>
          <div className="my-account-result__row">
            <div className="my-account-result__item-icon-wrapper my-account-result__item-icon-wrapper--prize">
              {!isPT && (
                <span className="my-account-result__item-text">
                  {t("prize")}
                </span>
              )}
              {(!isMobile || isPT) && (
                <div className="my-account-result__item-icon">
                  <ReactSVG src="/static/images/icon-prize.svg" />
                </div>
              )}
              <span className="my-account-result__item">
                {getPrize(awardPlacement?.[accountResult?.rank])}
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default memo(MyAccountResult);
