import actionTypes from '../actionTypes';

export function requestRaces() {
  return {
    type: actionTypes.RACES_REQUEST,
  };
}

export function successRaces(payload) {
  return {
    type: actionTypes.RACES_SUCCESS,
    payload,
  };
};

export function makeRaceInActive(payload) {
  return {
    type: actionTypes.MAKE_RACE_INACTIVE,
    payload,
  };
};

export function failRaces(e) {
  return {
    type: actionTypes.RACES_FAILURE,
    exception: e,
  };
}

export function selectRace(payload) {
  return {
    type: actionTypes.SELECT_RACE,
    payload,
  };
}
