import PrizepoolTable from "./PrizepoolTable";
import "./PrizePool.scss";

const PrizePool = ({ id, visibleItems }) => {
  return (
    <div className="prize-pool">
      <PrizepoolTable visibleItems={visibleItems} id={id} />
    </div>
  );
};

export default PrizePool;
