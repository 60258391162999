import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import Tabs from "../../Tabs";
import CountDownTimer from "../../CountDownTimer";
import MyAccountResult from "../../MyAccountResult";
import JoinToRace from "../../JoinToRace";
import Button from "../../common/Button";

import EmptyAccountResult from "../../EmptyAccountResult";
import { useFormatCurrency } from "../../../hooks/useFormatCurrency";
import "./MobileLeaderboardAndPrizepool.scss";

const MobileLeaderboardAndPrizepool = ({
  visibleItems,
  item,
  currentTable,
  TableView,
  handleShowMore,
  handleCurrentTableChange,
  handleCountDownAction,
  hasAccountResult,
}) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const { name, prize_pool, currency, expire_time, id, accountsCount } =
    item;

  return (
    <div className="mobile-leaderboard-and-prizepool">
      <div className="mobile-leaderboard-and-prizepool__header">
        <div className="mobile-leaderboard-and-prizepool__header-top">
          <div className="mobile-leaderboard-and-prizepool__header-img" />
          <div className="mobile-leaderboard-and-prizepool__header-name-id-wrapper">
            <span className="mobile-leaderboard-and-prizepool__name">
              {name}
            </span>
            <span className="mobile-leaderboard-and-prizepool__id">#{id}</span>
          </div>
        </div>
        <div className="mobile-leaderboard-and-prizepool__header-bottom">
          <div className="mobile-leaderboard-and-prizepool__header-prize-wrapper">
            <div className="mobile-leaderboard-and-prizepool__header-prize-img" />
            <span className="mobile-leaderboard-and-prizepool__header-prize">
              {formatCurrency(prize_pool, currency)}
            </span>
          </div>
          <div className="mobile-leaderboard-and-prizepool__heder-info-wrapper">
            <div className="mobile-leaderboard-and-prizepool__heder-info">
              <span className="mobile-leaderboard-and-prizepool__heder-info-label">
                {t("endsIn")}:
              </span>
              <div className="mobile-leaderboard-and-prizepool__heder-info-value">
                <CountDownTimer
                  withoutLabels
                  withDots
                  countdownTimestampMs={expire_time}
                  countDownCallback={handleCountDownAction}
                />
              </div>
            </div>
            <div className="mobile-leaderboard-and-prizepool__heder-info">
              <span className="mobile-leaderboard-and-prizepool__heder-info-label mobile-leaderboard-and-prizepool__heder-info-label--icon">
                <ReactSVG src="static/images/icon-enrolled.svg" />
              </span>
              <span className="mobile-leaderboard-and-prizepool__heder-info-value mobile-leaderboard-and-prizepool__heder-info-value--icon">
                {accountsCount}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-leaderboard-and-prizepool__body">
        <div className="mobile-leaderboard-and-prizepool__tab-my-account-result-table-view-wrapper">
          <div className="mobile-leaderboard-and-prizepool__tabs-wrapper">
            <Tabs
              tabs={[
                { label: t("leaderboard"), value: "leaderboard" },
                { label: t("prizepool"), value: "prizepool" },
              ]}
              selectedTab={currentTable}
              onChange={handleCurrentTableChange}
              variant="secondary"
              backgroundColor="var(--BG-Surface-3, #2E3138)"
            />
          </div>
          {hasAccountResult ? <div className="mobile-leaderboard-and-prizepool__my-account-result-wrapper">
            <MyAccountResult id={item.id} />
          </div> : <div className="mobile-leaderboard-and-prizepool__empty-result-wrapper">
            <EmptyAccountResult />
          </div>}
          <div className="mobile-leaderboard-and-prizepool__table-view-wrapper">
            <TableView id={String(id)} visibleItems={visibleItems} />
          </div>
        </div>
        <div className="mobile-leaderboard-and-prizepool__actions-wrapper">
          <Button
            variant="secondary"
            disabled={visibleItems === 'all'}
            onClick={handleShowMore}
          >
            {t("showMore")}
          </Button>
          <JoinToRace race={item} />
        </div>
      </div>
    </div>
  );
};

export default MobileLeaderboardAndPrizepool;
