import { memo } from "react";
import { classNames } from "../../../helpers/classNames";

import "./index.scss";

function Button({ children, onClick, disabled, variant, size, className, style }) {
  const buttonClassNames = classNames({
    button: true,
    [`button--${variant}`]: variant,
    [`button--${size}`]: size,
    [`${className}`]: className,
  });

  return (
    <button
      disabled={!!disabled}
      onClick={onClick}
      className={buttonClassNames}
      style={style}
    >
      {children}
    </button>
  );
}

export default memo(Button);
