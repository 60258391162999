import { use } from "i18next";
import actionTypes from "./actionTypes";

//queryParams
const queryParamsInitial = {};

export const queryParams = (state = queryParamsInitial, action) => {
  switch (action.type) {
    case actionTypes.SET_QUERY_PARAMS:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.LOGOUT_SET_QUERY_PARAMS:
      return {
        ...state,
        playerToken: null,
      };
    case actionTypes.LOGIN_SET_QUERY_PARAMS:
      return {
        ...state,
        playerToken: action.payload.token,
        ...action.payload,
      };
    default:
      return state;
  }
};

//races
const racesInitial = {
  data: [],
  isLoading: false,
};

export const races = (state = racesInitial, action) => {
  switch (action.type) {
    case actionTypes.RACES_REQUEST:
      return { ...state, isLoading: true };
    case actionTypes.RACES_SUCCESS:
      return { data: action.payload, isLoading: false };
    case actionTypes.RACES_FAILURE:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

//racesLeaderboard
const racesLeaderboardInitial = {};

export const racesLeaderboard = (state = racesLeaderboardInitial, action) => {
  switch (action.type) {
    case actionTypes.RACES_LEADERBOARD_REQUEST:     
    if (state[action.raceId]) {
      return {
        ...state,
        [action.raceId]: {
          ...state[action.raceId],
          pending: true,
          failed: false,
        },
      };
    } else {
        const tempObj = {
          [action.raceId]: {
            pending: true,
            failed: false,
          }
        };
        return {...state, ...tempObj};
      }

    case actionTypes.RACES_LEADERBOARD_SUCCESS:
      return {
        ...state,
        [action.payload.raceId]: {
          data: [...action.payload.leaderBoard[action.payload.raceId]],
          pending: false,
          failed: false,
        },
      };
    case actionTypes.RACES_LEADERBOARD_FAILURE:
      return {
        ...state,
        [state[action.raceId]]: {
          ...state[action.raceId],
          pending: false,
          failed: true,
        },
      };
    case actionTypes.RACES_LEADERBOARD_RESET:
      return racesLeaderboardInitial;
    default:
      return state;
  }
};

//racesAccountResult
const racesAccountResultInitial = {};

export const racesAccountResult = (
  state = racesAccountResultInitial,
  action
) => {
  switch (action.type) {
    case actionTypes.RACES_LEADERBOARD_SUCCESS:
      return { ...state, ...action.payload?.accountResult };
    case actionTypes.RACES_LEADERBOARD_RESET:
      return racesAccountResultInitial;
    case actionTypes.RACES_LEADERBOARD_FAILURE:
      return racesAccountResultInitial;
    default:
      return state;
  }
};

//toasts
const toastsInitial = [];
export const toasts = (state = toastsInitial, action) => {
  switch (action.type) {
    case "JOIN_TO_RACE_FAILURE": {
      const msg =
        action?.exception?.message ||
        action?.exception ||
        "Failed to join to race";
      if (msg) {
        return [
          ...state,
          {
            msgTranslation: msg,
            appearance: "error",
            autoDismiss: true,
          },
        ];
      } else {
        return state;
      }
    }
    default:
      return state;
  }
};

//isJoinToRacePending
const isJoinToRacePendingInitial = false;

export const isJoinToRacePending = (
  state = isJoinToRacePendingInitial,
  action
) => {
  switch (action.type) {
    case actionTypes.JOIN_TO_RACE_REQUEST:
      return true;
    case actionTypes.UPDATE_JOIN_TO_RACE_PENDING_STATUS:
    case actionTypes.JOIN_TO_RACE_FAILURE:
      return false;
    default:
      return state;
  }
};

//selectedRace
const selectedRaceInitial = {};

export const selectedRace = (state = selectedRaceInitial, action) => {
  switch (action.type) {
    case actionTypes.SELECT_RACE:
      return action.payload;
    default:
      return state;
  }
};

//currentRace
const currentRaceInitial = "pending";

export const currentRace = (state = currentRaceInitial, action) => {
  switch (action.type) {
    case actionTypes.RACES_SUCCESS:
      return action?.payload?.find((race) => race.isActive) || "";
    case actionTypes.MAKE_RACE_INACTIVE:
      return null;
    case actionTypes.RACES_FAILURE:
      return currentRaceInitial;
    default:
      return state;
  }
};

const CMSResponseInitial = {
  data: null,
  pending: false,
  failed: false,
};

export const CMSResponse = (state = CMSResponseInitial, action) => {
  switch (action.type) {
    case actionTypes.GET_CMS_RESPONSE_REQUEST:
      return { failed: false, pending: true, data: null };
    case actionTypes.GET_CMS_RESPONSE_FAILURE:
      return { ...state, failed: true, pending: false };
    case actionTypes.GET_CMS_RESPONSE_SUCSESS:
      return { failed: false, pending: false, data: action.payload };
    default:
      return state;
  }
};


const raceMissionTemplatesInitial = {
  data: null,
  pending: false,
  failed: false,
};

export const raceMissionTemplates = (state = raceMissionTemplatesInitial, action) => {
  switch (action.type) {
    case actionTypes.GET_RACE_MISSION_TEMPLATES_REQUEST:
      return { failed: false, pending: true, data: null };
    case actionTypes.GET_RACE_MISSION_TEMPLATES_FAILURE:
      return { ...state, failed: true, pending: false };
    case actionTypes.GET_RACE_MISSION_TEMPLATES_SUCSESS:
      return { failed: false, pending: false, data: action.payload };
    case actionTypes.GET_RACE_MISSION_TEMPLATES_RESET:
      return raceMissionTemplatesInitial;
    default:
      return state;
  }
};

const dialogManagerInitial = {
  dialogs: {},
};

export const dialogManager = (state = dialogManagerInitial, action) => {
  switch (action.type) {
    case actionTypes.OPEN_DIALOG:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          [action.payload.dialogType]: action.payload,
        },
      };
    case actionTypes.CLOSE_DIALOG:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          [action.payload]: undefined,
        },
      };
    default:
      return state;
  }
};


export const awardPlacement = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.STORE_AWARD_PLACEMENT:
      const { id, awardData } = action.payload;
      return {
        ...state,
        [id]: awardData
      };
    default:
      return state;
  }
};
