import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import { hasShowProductBanner } from "../../../store/selectors";
import settings from "../../../configs/settings";
import Banner from "../../Banner";
import Tabs from "../../Tabs";
import ThemeSwitcher from "../../ThemeSwitcher";
import "./MobileRace.scss";

const MobileRace = ({ currentView, handleCurrentViewChange, SelectedView }) => {
  const { t } = useTranslation();
  const showProductBanner = useSelector(hasShowProductBanner);

  const handleHowToPlay = () => {
    if (settings?.howToPlayExternalLink) {
      window.open(settings.howToPlayExternalLink, '_blank');
      return;
    }
    handleCurrentViewChange('howToPlay')
  }

  return (
    <div className="mobile-container">
      <div className="mobile-race">
        {showProductBanner && (
          <div className="mobile-race__banner-wrapper">
            <Banner />
          </div>
        )}
        <div className="mobile-race__title-how-top-play-wrapper">
          <span className="mobile-race__title">{t("casinoRace")}</span>
          <div onClick={handleHowToPlay} className="mobile-race__how-to-play-wrapper">
            <div className="mobile-race__how-to-play-icon">
              <ReactSVG src="/static/images/how-to-play-icon.svg" />
            </div>
            <span className="mobile-race__how-to-play-text">
              {t("howToPlay")}
            </span>
          </div>
        </div>
        <p className="mobile-race__description">{t("raceDescription")}</p>
        <div className="mobile-race__tabs-wrapper">
          <Tabs
            tabs={[
              { label: t("ongoing"), value: "ongoing" },
              { label: t("upcoming"), value: "upcoming" },
              { label: t("previousRaces"), value: "previousRaces" },
            ]}
            selectedTab={currentView}
            onChange={handleCurrentViewChange}
            variant="secondary"
          />
        </div>
        <div className="mobile-race__view-wrapper">
          <SelectedView />
        </div>
        {settings?.theme?.hasThemeSwitcher && (
          <div className="mobile-race__theme-switcher-wrapper">
            <ThemeSwitcher />
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileRace;
