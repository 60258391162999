import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { ToastProvider } from "react-toast-notifications";
import { getLang, getLanguage } from "./store/selectors";
import { classNames } from "./helpers/classNames";
import i18n from "./i18n";
import Race from "./components/Race/";
import ToastManager from "./components/ToastManager";
import "./styles/index.scss";
import Version from "./components/Version/Version";
import { changeTheme } from "./helpers/changeTheme";
import { THEME } from "./constants/theme";
import settings from "./configs/settings";
import Dialogs from "./components/Dialogs";
import moment from "moment";
import { MOMENT_LOCALES } from "./constants/moment-locale";
import { loginSetQueryParams, logoutSetQueryParams } from "./store/actionCreators/queryParams";

function App() {
  const dispatch = useDispatch();
  const LANGUAGE = useSelector(getLanguage);
  const LANG = useSelector(getLang);
  const language = LANGUAGE || LANG;
  if (MOMENT_LOCALES?.[language]) {
    moment.defineLocale(language || "en", MOMENT_LOCALES[language]);
    moment.locale(language);
  }

  const handleFrameMessages = (e) => {
    switch (e.data.type) {
      case "CHANGE_LANGUAGE":
        let { language } = e.data;
        i18n.changeLanguage(language);
        break;
      case 'GD_LOGIN_ACTION':
        dispatch(loginSetQueryParams(e.data.payload));
        break;
      case 'GD_LOGOUT_ACTION':
        dispatch(logoutSetQueryParams());
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  useEffect(() => {
    window.addEventListener("message", (e) => handleFrameMessages(e));
    changeTheme(THEME?.[settings?.theme?.style]);
    return () =>
      window.removeEventListener("message", (e) => handleFrameMessages(e));
  }, []);

  return (
    <ToastProvider>
      <ToastManager />
      <div className={classNames("app", { mobile: isMobile })}>
        <Dialogs />
        <Race />
        <div className="app__version-wrapper">
          <Version />
        </div>
      </div>
    </ToastProvider>
  );
}

export default App;
