import { useTranslation } from "react-i18next";
import settings from "../../../configs/settings";
import Tabs from "../../Tabs";
import Banner from "../../Banner";
import ThemeSwitcher from "../../ThemeSwitcher";
import "./DesktopRace.scss";
import { useSelector } from "react-redux";
import { hasShowProductBanner } from "../../../store/selectors";

const DesktopRace = ({
  currentView,
  handleCurrentViewChange,
  SelectedView,
}) => {
  const { t } = useTranslation();
  const showProductBanner = useSelector(hasShowProductBanner);

  return (
    <div className="container">
      <div className="race">
        {showProductBanner && (
          <div className="race__banner-wrapper">
            <Banner />
          </div>
        )}
        <div className="race__title-tabs-wrapper">
          <span className="race__title">{t("casinoRace")}</span>
          <div className="race__tabs-wrapper">
            <Tabs
              tabs={[
                { label: t("ongoing"), value: "ongoing" },
                { label: t("upcoming"), value: "upcoming" },
                { label: t("previousRaces"), value: "previousRaces" },
                { label: t("howToPlay"), value: "howToPlay" },
              ]}
              selectedTab={currentView}
              onChange={handleCurrentViewChange}
            />
          </div>
        </div>
        <p className="race__description">{t("raceDescription")}</p>
        <div className="race__view-wrapper">
          <SelectedView />
        </div>
        {settings?.theme.hasThemeSwitcher && (
          <div className="race__theme-switcher-wrapper">
            <ThemeSwitcher />
          </div>
        )}
      </div>
    </div>
  );
};

export default DesktopRace;
