import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getDialogs } from "../../store/selectors";
import MissionInfo from "./MissionInfo";

const Dialogs = () => {
  const dialogs = useSelector(getDialogs);
  useEffect(() => {
    if (dialogs && Object.values(dialogs).filter(item => Boolean(item))?.length) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [dialogs])
  return (
    <>{dialogs?.missionInfo && <MissionInfo {...dialogs.missionInfo} />}</>
  );
};

export default Dialogs;
