import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import {
  getDesktopHowToPlayContent,
  getLang,
  getLanguage,
  getMobileHowToPlayContent,
  getStrapiUrl,
} from "../../../store/selectors";
import "./HowToPlayView.scss";
import settings from "../../../configs/settings";
import { isValidHttpsUrl, updateImageSrc } from "../../../helpers/helpers";

const HowToPlayView = () => {
  const LANGUAGE = useSelector(getLanguage);
  const LANG = useSelector(getLang);
  const language = LANGUAGE || LANG;
  const desktopHowToPlay = useSelector(getDesktopHowToPlayContent)(language);
  const mobileHowToPlay = useSelector(getMobileHowToPlayContent)(language);
  const howToPlay = isMobile ? mobileHowToPlay : desktopHowToPlay;
  const strapiUrl = useSelector(getStrapiUrl);  

  return (
    <div className="how-to-play-view">
      <div
        className="how-to-play-view__content"
        dangerouslySetInnerHTML={{
          __html: updateImageSrc(howToPlay, strapiUrl && isValidHttpsUrl(strapiUrl) ? strapiUrl : settings?.FILE_STORAGE_ADDRESS),
        }}
      />
    </div>
  );
};

export default HowToPlayView;
