import { memo, useMemo } from "react";
import { ReactSVG } from "react-svg";
import { Virtuoso } from "react-virtuoso";
import { classNames } from "../../helpers/classNames";
import Spinner from "../common/Spinner";
import { maskString } from "../../helpers/helpers";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import ReactCountryFlag from "react-country-flag";
import { useSelector } from "react-redux";
import {
  getAwardPlacement,
  getLeaderboard,
  isRacesLeaderboardending,
} from "../../store/selectors";
import { usePrizeDrawing } from "../../hooks/usePrizeDrawing";
import { COUNTRIES_CODE } from "../../constants/countries";
import settings from "../../configs/settings";

const STATIC_HEADER = (t) => [
  { value: t("rank"), icon: "icon-up-down-arrows" },
  { value: t("name") },
  { value: t("score"), icon: isMobile ? "" : "icon-score" },
  { value: t("prize"), icon: isMobile ? "" : "icon-prize" },
];

const LeaderboardTable = ({ id, visibleItems }) => {
  const { t } = useTranslation();
  const pending = useSelector(isRacesLeaderboardending)(id);
  const leaderboard = useSelector(getLeaderboard)(id);
  const awardPlacement = useSelector(getAwardPlacement)(id);
  const getPrize = usePrizeDrawing(id);

  const visibleLeaderboard = useMemo(() => {
    return visibleItems === "all"
      ? leaderboard
      : leaderboard?.slice(0, visibleItems);
  }, [leaderboard, visibleItems]);

  const header = STATIC_HEADER(t);
  const noData = !leaderboard?.length;

  return (
    <div
      className={classNames("table", `table--col-${header.length}`, {
        "table--no-data": noData,
      })}
    >
      {pending && (
        <div className="table__pending">
          <Spinner />
        </div>
      )}
      <div className="table__header">
        <div className="table__inner">
          {header.map((item, index) => (
            <div key={index} className="table__header-item">
              <span className="table__header-item-text">{item.value}</span>
              {item?.icon && (
                <div className="table__header-item-icon">
                  <ReactSVG src={`/static/images/${item.icon}.svg`} />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div
        className={classNames("table__body", {
          "table__body--all": visibleItems === "all",
        })}
      >
        <Virtuoso
          className="table__body-inner"
          data={visibleLeaderboard}
          itemContent={(index, item) => {
            return (
              <div
                className={classNames("table__body-row", {
                  "table__body-row--first": item?.rank === 1,
                  "table__body-row--second": item?.rank === 2,
                  "table__body-row--third": item?.rank === 3,
                  "table__body-row--even": index % 2 === 0,
                  "table__body-row--odd": index % 2 !== 0,
                })}
              >
                <div className="table__body-row-inner">
                  <div className="table__body-row-item">
                    <div className="table__body-row-item-inner">
                      <span>{item?.rank}</span>
                    </div>
                  </div>
                  <div className="table__body-row-item">
                    <div className="table__body-row-item-inner">
                      <span>{maskString(item?.username)}</span>
                      {settings?.showFlagsOnLeaderboard && item?.country_code && COUNTRIES_CODE?.[item.country_code] && (                        
                        <ReactCountryFlag
                          countryCode={COUNTRIES_CODE[item.country_code]}
                          className="emojiFlag"
                          style={{ marginLeft: 4, fontSize: 18 }}
                          svg
                        />
                      )}
                    </div>
                  </div>
                  <div className="table__body-row-item">
                    <div className="table__body-row-item-inner">
                      <span>{item?.points}</span>
                    </div>
                  </div>
                  <div className="table__body-row-item">
                    <div className="table__body-row-item-inner">
                      <span>{getPrize(awardPlacement?.[item?.rank])}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default memo(LeaderboardTable);
