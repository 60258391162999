import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { TABLE_VIEW } from "../../../constants/tableView";
import { requestRacesLeaderboard } from "../../../store/actionCreators/racesLeaderboard";
import DesktopPreviousRacesView from "./DesktopPreviousRacesView/DesktopPreviousRacesView";
import MobilePreviousRacesView from "./MobilePreviousRacesView";
import { getPreviousRaces } from "../../../store/selectors";

const CURRENT_TABLE_INITIAL = "leaderboard";

const PreviousRacesView = () => {
  const dispatch = useDispatch();
  const previousRaces = useSelector(getPreviousRaces);
  const [currentTable, setCurrentTable] = useState(CURRENT_TABLE_INITIAL);
  
  useEffect(() => {
    if (previousRaces) {
      dispatch(requestRacesLeaderboard({ raceId: previousRaces?.[0]?.id, limit: previousRaces?.[0]?.accountsCount || 20 }));
    }
  }, [previousRaces]);

  const handleCurrentTableChange = useCallback((view) => {
    setCurrentTable(view);
  }, []);

  const TableView = TABLE_VIEW.get(currentTable);

  if (!previousRaces?.length) return null;

  const Component = isMobile
    ? MobilePreviousRacesView
    : DesktopPreviousRacesView;
  return (
    <Component
      currentTable={currentTable}
      previousRaces={previousRaces}
      TableView={TableView}
      handleCurrentTableChange={handleCurrentTableChange}
    />
  );
};

export default PreviousRacesView;
