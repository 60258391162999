import { useTranslation } from "react-i18next";
import { useFormatCurrency } from "../../hooks/useFormatCurrency";
import "./MissionCard.scss";

const MissionCard = ({ mission }) => {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();

  return (
    <div className="mission-card">
      <div className="mission-card__header">
        <span className="mission-card__header-text">{t('missionDetails')}</span>
      </div>
      <div className="mission-card__body">
        <div className="mission-card__thubnail-description-image-1-wrapper">
          <div className="mission-card__thubnail-description-image-1-wrapper-inner">
            <div className="mission-card__thubnail-description-wrapper">
              <div className="mission-card__thubnail">
                <img src={mission?.img_url} alt="thubnail" />
              </div>
              <span className="mission-card__description">{mission?.title}</span>
            </div>
            <div className="mission-card__image-1-wrapper" />
          </div>
        </div>
        <div className="mission-card__progress-bar-fun-balance-wrapper">
          <div className="mission-card__progress-bar-wrapper">
            <div className="mission-card__progress-bar-texts-wrapper">
              <span className="mission-card__progress-bar-wager">{`${t('wager')}: ${mission?.trigger?.points}`}</span>
              <span className="mission-card__progress-bar-progress">{t('progress')}: 0%</span>
            </div>
            <div className="mission-card__progress-bar" />
            <div className="mission-card__prize-wrapper">
              <div className="mission-card__prize-wrapper-inner">
                <div className="mission-card__prize-image" />
                <span className="mission-card__prize">{`${t('prize')}: ${formatCurrency(mission?.prize?.amount, mission?.prize?.currency)}`}</span>
              </div>
            </div>
          </div>
          <div className="mission-card__fun-balance-wrapper">
            <div className="mission-card__fun-balance-img" />
            <span className="mission-card__fun-balance">{t('funBalance')}</span>
            <span className="mission-card__fun-balance-value">{mission?.fun_balance}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionCard;
