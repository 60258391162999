import moment from "moment";
import { createSelector as cs } from "reselect";

import { prop } from "../helpers/helpers";

const queryParams = prop("queryParams");
const CMSResponse = prop("CMSResponse");
const racesLeaderboard = prop("racesLeaderboard");
const races = prop("races");
const raceMissionTemplates = prop('raceMissionTemplates');
const dialogManager = prop("dialogManager");
const awardPlacement = prop("awardPlacement");

export const isRaceMissionTemplatesPending = cs(raceMissionTemplates, prop('pending'));
export const isRaceMissionTemplatesFailed = cs(raceMissionTemplates, prop('failed'));
export const getRaceMissionTemplates = cs(raceMissionTemplates, prop('data'));
export const getRaceMissionTemplate = cs(
  getRaceMissionTemplates,
  (missionTemplates) => (id) => missionTemplates?.find((m) => m?.id === id)
);

export const getDialogs = cs(dialogManager, prop("dialogs"));

export const getCurrentRace = prop("currentRace");

export const getRaces = cs(races, prop("data"));
export const isRacesLoading = cs(races, prop("isLoading"));

export const getToken = cs(queryParams, prop("playerToken"));
export const getStrapiUrl = cs(queryParams, prop("strapiUrl"));
export const getSkinId = cs(queryParams, prop("skinId"));
export const getLanguage = cs(queryParams, prop("language"));
export const getLang = cs(queryParams, prop("lang"));
export const getRaceVisualMode = cs(queryParams, prop("onlyInProgress"));
const getShowPruductBanner = cs(queryParams, prop("showPruductBanners"));
export const hasShowProductBanner = cs(
  getShowPruductBanner,
  (query) => query !== "false"
);

export const isShowFormattedAmountWithDot = cs(
  cs(queryParams, prop("showFormattedAmountWithDot")),
  (query) => query === "true"
);

export const isCMSResponsePending = cs(CMSResponse, prop("pending"));
export const getCMSResponse = cs(CMSResponse, prop("data"));

export const getDesktopBanners = cs(
  getCMSResponse,
  (b) => b?.banners?.desktopBannerURL
);
export const getMobileBanners = cs(
  getCMSResponse,
  (b) => b?.banners?.mobileBannerURL
);

export const getDesktopHowToPlayContent = cs(
  getCMSResponse,
  (h) => (language) => h?.[language]?.howToPlay?.desktopHowToPlay
);
export const getMobileHowToPlayContent = cs(
  getCMSResponse,
  (h) => (language) => h?.[language]?.howToPlay?.mobileHowToPlay
);

export const getUpcomingRaces = cs(getRaces, (races) =>
  races.filter(
    (race) => !race.isActive && !moment(race.expire_time).isBefore(moment())
  )
);

export const getPreviousRaces = cs(getRaces, (races) =>
  races.filter(
    (race) => !race.isActive && moment(race.expire_time).isBefore(moment())
  )
);

export const getOngoingRaces = cs(getRaces, (races) =>
  races.filter((race) => race?.isActive)
);

export const getSelectedRace = prop("selectedRace");

export const getRacesLeaderboard = cs(racesLeaderboard, (state) => state);
export const isRacesLeaderboardending = cs(
  getRacesLeaderboard,
  (leaderboard) => (id) => leaderboard?.[id]?.pending
);

export const getToasts = prop("toasts");

export const getIsJoinToRacePending = prop("isJoinToRacePending");

export const getRacesAccountResult = cs(
  prop("racesAccountResult"),
  (accountResult) => (id) => accountResult?.[id] || {}
);

export const getAwardPlacement = cs(
  awardPlacement,
  (placement) => (id) => placement?.[id] || {}
);

export const getLeaderboard = cs(
  getRacesLeaderboard,
  (leaderboard) => (id) => leaderboard?.[id]?.data
);

export const getRace = cs(
  getRaces,
  (race) => (id) => race?.find((item) => String(item?.id) === String(id))
);